<template>
  <div class="poi-wrapper">
    <FilterType @changeInCategory="selectedCategories = $event"></FilterType>

    <div>
      <Map :mapData="filteredMapData"></Map>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  components: {
    Map: () => import("@/components/Map.vue"),
    FilterType: () => import("./PointOfInterestFilter.vue")
  },
  data() {
    return {
      mapData: [],
      selectedCategories: []
    };
  },
  created() {
    this.fetchData();
  },
  computed: {
    /**
     * Filters the map data according to selected categories
     */
    filteredMapData() {
      return this.mapData.filter((ele) => {
        if (this.selectedCategories.includes(ele.surveyed_location_type)) {
          return ele;
        }
      });
    }
  },
  methods: {
    /**
     * Fetches POI data from kobo API
     */
    fetchData() {
      this.$store.dispatch("setData", {
        mutation: "SET_LOADING",
        data: true
      });

      axios
        .get(process.env.VUE_APP_KOBO_API)
        .then(({ data }) => {
          this.mapData = data;
        })
        .catch((err) => {
          // eslint-disable-next-line
          console.error(err);
        })
        .finally(() => {
          this.$store.dispatch("setData", {
            mutation: "SET_LOADING",
            data: false
          });
        });
    }
  }
};
</script>

<style scoped lang="scss">
.poi-wrapper {
  display: flex;
}
</style>
